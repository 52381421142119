import {
  apiRentalMarketDistrict,
  apiRentalMarketArea,
  apiEmployementStatusPercentages,
  apiHouseholdPercentages,
  apiTenurePercentagesHousehold,
} from 'api/avm';
import RentalMarketReport from '../../RentalMarketReport/RentalMarketReport';
import { useMemo } from 'react';
import { combineRentsData, formatRentsData } from 'utils/helpers';
import { useQuery } from '@tanstack/react-query';

const RentalMarketReportWrapper = ({ district_code, area_code, property_type, number_of_bedrooms }) => {
  const districtQuery = {
    district: district_code,
    limit: 20,
    offset: 0,
  };

  const areaQuery = {
    area_code,
    limit: 20,
    offset: 0,
  };

  const {
    data: rentalMarketDistrict = [],
    isLoading: rentalMarketDistrictDataLoading,
    isSuccess: rentalMarketDistrictDataLoaded,
  } = useQuery(['rentalMarketDistrict', districtQuery], ({ signal }) => apiRentalMarketDistrict(districtQuery, signal));

  const {
    data: rentalMarketArea = [],
    isLoading: rentalMarketAreaDataLoading,
    isSuccess: rentalMarketAreaDataLoaded,
  } = useQuery(['rentalMarketArea', areaQuery], ({ signal }) => apiRentalMarketArea(areaQuery, signal));

  const combinedData = useMemo(() => {
    if (rentalMarketDistrictDataLoaded && rentalMarketAreaDataLoaded) {
      const districtFormattedData = formatRentsData(rentalMarketDistrict);
      const areaFormattedData = formatRentsData(rentalMarketArea);
      return combineRentsData(districtFormattedData, areaFormattedData);
    }
    return { Flat: {}, House: {} };
  }, [rentalMarketDistrict, rentalMarketArea, rentalMarketDistrictDataLoaded, rentalMarketAreaDataLoaded]);

  const isLoading = rentalMarketDistrictDataLoading || rentalMarketAreaDataLoading;

  const { data: employmentStatusData = [], isLoading: employmentStatusDataLoading } = useQuery(
    ['districtEmploymentData', { district: district_code }],
    ({ signal }) => apiEmployementStatusPercentages({ district: district_code }, signal),
  );

  const uniqueEmploymentData = employmentStatusData.reduce((acc, item) => {
    if (!acc.some((entry) => entry.employment_group === item.employment_group)) {
      acc.push(item);
    }
    return acc;
  }, []);

  const employmentCategories = uniqueEmploymentData.map((item) => item.employment_group);
  const employmentDistrictData = Object.fromEntries(
    uniqueEmploymentData.map((item) => [item.employment_group, (item.percentage_by_district || 0) / 100]),
  );
  const employmentAreaData = Object.fromEntries(
    uniqueEmploymentData.map((item) => [item.employment_group, (item.percentage_by_area || 0) / 100]),
  );

  const { data: householdData = [], isLoading: householdDataLoading } = useQuery(
    ['districtHouseholdData', { district: district_code }],
    ({ signal }) => apiHouseholdPercentages({ district: district_code }, signal),
  );

  const uniqueHouseholdData = householdData.reduce((acc, item) => {
    if (!acc.some((entry) => entry.household_group === item.household_group)) {
      acc.push(item);
    }
    return acc;
  }, []);

  const householdCategories = uniqueHouseholdData.map((item) => item.household_group);
  const householdDistrictData = Object.fromEntries(
    uniqueHouseholdData.map((item) => [item.household_group, (item.percentage_by_district || 0) / 100]),
  );
  const householdAreaData = Object.fromEntries(
    uniqueHouseholdData.map((item) => [item.household_group, (item.percentage_by_area || 0) / 100]),
  );

  const { data: tenureData = [], isLoading: tenureDataLoading } = useQuery(
    ['districtTenureData', { district: district_code }],
    ({ signal }) => apiTenurePercentagesHousehold({ district: district_code }, signal),
  );

  const uniqueTenureData = tenureData.reduce((acc, item) => {
    if (!acc.some((entry) => entry.tenure_group === item.tenure_group)) {
      acc.push(item);
    }
    return acc;
  }, []);

  const tenureCategories = uniqueTenureData.map((item) => item.tenure_group);
  const tenureDistrictData = Object.fromEntries(
    uniqueTenureData.map((item) => [item.tenure_group, (item.percentage_by_district || 0) / 100]),
  );
  const tenureAreaData = Object.fromEntries(
    uniqueTenureData.map((item) => [item.tenure_group, (item.percentage_by_area || 0) / 100]),
  );

  const handleChartImagesChange = (updatedChartImages) => {};

  return (
    <>
      {combinedData && (
        <RentalMarketReport
          district_code={district_code}
          area_code={area_code}
          property_type={property_type}
          number_of_bedrooms={number_of_bedrooms}
          employmentCategories={employmentCategories}
          employmentDistrictData={employmentDistrictData}
          employmentAreaData={employmentAreaData}
          householdCategories={householdCategories}
          householdDistrictData={householdDistrictData}
          householdAreaData={householdAreaData}
          tenureCategories={tenureCategories}
          tenureDistrictData={tenureDistrictData}
          tenureAreaData={tenureAreaData}
          rentalMarketDistrict={rentalMarketDistrict}
          rentalMarketArea={rentalMarketArea}
          combinedData={combinedData}
          employmentStatusDataLoading={employmentStatusDataLoading}
          householdDataLoading={householdDataLoading}
          tenureDataLoading={tenureDataLoading}
          isLoading={isLoading}
          onChartImagesChange={handleChartImagesChange}
        />
      )}
    </>
  );
};

export default RentalMarketReportWrapper;
