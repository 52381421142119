import BarChart from './BarChart';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const keyMapping = {
  Studio: ['Flat', 'Studio'],
  '1 Bed Flat': ['Flat', '1 Bed'],
  '2 Bed Flat': ['Flat', '2 Bed'],
  '3+ Bed Flat': ['Flat', '3 Bed'],
  '2 Bed House': ['House', '2 Bed'],
  '3 Bed House': ['House', '3 Bed'],
  '4+ Bed House': ['House', '4 Bed'],
};

const quartileKeyMapping = {
  'Lower Quartile': 'lowerQuartile',
  'Median Quartile': 'medianQuartile',
  'Upper Quartile': 'upperQuartile',
};

const RentsPaidChartWrapper = ({ combinedData, district_code, area_code, renderImage }) => {
  if (!combinedData) return '';

  const resultMapping = {
    'Lower Quartile': { district: {}, area: {} },
    'Median Quartile': { district: {}, area: {} },
    'Upper Quartile': { district: {}, area: {} },
  };

  for (const [newKey, [category, subCategory]] of Object.entries(keyMapping)) {
    for (const [quartile, quartileKey] of Object.entries(quartileKeyMapping)) {
      resultMapping[quartile].district[newKey] = combinedData[category][subCategory][quartileKey].district;
      resultMapping[quartile].area[newKey] = combinedData[category][subCategory][quartileKey].area;
    }
  }

  const categories = Object.keys(keyMapping);
  const quartileOptions = Object.keys(resultMapping);

  return (
    <>
      {quartileOptions.map((quartile, index) => {
        const districtData = resultMapping[quartile].district;
        const areaData = resultMapping[quartile].area;

        return (
          <div key={quartile} className="bar-chart base-bar-chart pb-3 w-full">
            <h2 className="font-medium text-black text-lg mb-2">{quartile}</h2>
            {districtData ? (
              <BarChart
                categories={categories}
                districtData={districtData}
                areaData={areaData}
                district_code={district_code}
                area_code={area_code}
                showLabels={true}
                showRate={false}
                renderImage={(imgURI) => renderImage(imgURI, quartile)}
              />
            ) : (
              ''
            )}
          </div>
        );
      })}
    </>
  );
};

export default RentsPaidChartWrapper;
