import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import RentsTable from './RentalMarketTables/RentsTable';
import MarketTable from './RentalMarketTables/MarketTable';
import SummaryStatistics from './RentalMarketTables/SummaryStatistics';
import DepthOfMarketTable from './RentalMarketTables/DepthOfMarketTable';
import RentsPaidChartWrapper from './RentalMarketTables/RentsPaidChartWrapper';
import EmploymentData from './RentalMarketTables/EmploymentData';
import TenureData from './RentalMarketTables/TenureData';
import HouseholdData from './RentalMarketTables/HouseholdData';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import { Document, PDFDownloadLink } from '@react-pdf/renderer';
import SecondaryButton from '../Dashboard/Buttons/SecondaryButton';
import RentalMarketReportPdf from './RentalMarketReportPdf';
import { flatCategories, houseCategories } from 'utils/constants';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const RentalMarketReport = ({
  district_code,
  area_code,
  property_type,
  number_of_bedrooms,
  employmentCategories,
  employmentDistrictData,
  employmentAreaData,
  householdCategories,
  householdDistrictData,
  householdAreaData,
  tenureCategories,
  tenureDistrictData,
  tenureAreaData,
  rentalMarketDistrict,
  rentalMarketArea,
  combinedData,
  isLoading,
  employmentStatusDataLoading,
  householdDataLoading,
  tenureDataLoading,
  onChartImagesChange,
}) => {
  const [chartImages, setChartImages] = useState({
    depthOfMarketChartImg: null,
    rentsPaidChartLowerQuartileImg: null,
    rentsPaidChartMedianQuartileImg: null,
    rentsPaidChartUpperQuartileImg: null,
    renterIncomesChartImg: null,
    householdProfileChartImg: null,
    tenureProfileChartImg: null,
  });

  const debouncedChartImages = useRef(null);

  useEffect(() => {
    clearTimeout(debouncedChartImages.current);
    debouncedChartImages.current = setTimeout(() => {
      onChartImagesChange(chartImages);
    }, 300); // Debounce the updates

    return () => clearTimeout(debouncedChartImages.current);
  }, [chartImages, onChartImagesChange]);

  const updateRentsPaidChartImage = useCallback((quartile, img) => {
    const keyMap = {
      'Lower Quartile': 'rentsPaidChartLowerQuartileImg',
      'Median Quartile': 'rentsPaidChartMedianQuartileImg',
      'Upper Quartile': 'rentsPaidChartUpperQuartileImg',
    };

    setChartImages((prev) => {
      if (prev[keyMap[quartile]] !== img) {
        return { ...prev, [keyMap[quartile]]: img };
      }
      return prev;
    });
  }, []);

  const updateChartImage = useCallback((key, img) => {
    setChartImages((prev) => {
      if (prev[key] !== img) {
        return { ...prev, [key]: img };
      }
      return prev;
    });
  }, []);

  const [pdfReady, setPdfReady] = useState(false);
  const [shouldRenderPdf, setShouldRenderPdf] = useState(false);
  const linkRef = useRef(null);

  const handleDownloadClick = () => {
    setShouldRenderPdf(true);
    setPdfReady(false);
  };

  useEffect(() => {
    if (pdfReady && linkRef.current) {
      linkRef.current.click();
      setShouldRenderPdf(false);
    }
  }, [pdfReady]);

  return (
    <>
      {combinedData && (
        <>
          <div>
            <div className="mb-2.5 flex flex-col lg:flex-row items-center justify-between">
              <div className="fs-20 text-black">Rental Market Report</div>
              <div className="flex items-center justify-center pr-4">
                <div className="inline-flex items-center ml-4">
                  District ({district_code})
                  <div
                    className="w-8 ml-2 h-5 border-2 border-white"
                    style={{ backgroundColor: '#3571E5', borderRadius: '6px' }}
                  ></div>
                </div>
                <div className="inline-flex items-center ml-4">
                  Area ({area_code})
                  <div
                    className="w-8 ml-2 h-5 border-2 border-white"
                    style={{ backgroundColor: '#FF4560', borderRadius: '6px' }}
                  ></div>
                </div>
              </div>

              <div>
                {shouldRenderPdf ? (
                  <PDFDownloadLink
                    document={
                      <Document>
                        <RentalMarketReportPdf
                          chartImages={chartImages}
                          combinedData={combinedData}
                          district_code={district_code}
                          property_type={property_type}
                          number_of_bedrooms={number_of_bedrooms}
                          area_code={area_code}
                          employmentCategories={employmentCategories}
                          employmentDistrictData={employmentDistrictData}
                          employmentAreaData={employmentAreaData}
                          householdCategories={householdCategories}
                          householdDistrictData={householdDistrictData}
                          householdAreaData={householdAreaData}
                          tenureCategories={tenureCategories}
                          tenureDistrictData={tenureDistrictData}
                          tenureAreaData={tenureAreaData}
                          depthOfMarketDistrict={rentalMarketDistrict}
                          depthOfMarketArea={rentalMarketArea}
                        />
                      </Document>
                    }
                    fileName="PropertyDetails.pdf"
                  >
                    {({ url, loading }) => {
                      if (!loading && url) {
                        setPdfReady(true);
                      }
                      return loading ? (
                        <div className="flex items-center h-10 ml-4">
                          <LoaderSvg className="animate-spin h-6 w-6 text-white mr-2" />
                        </div>
                      ) : (
                        <div ref={linkRef}>
                          <SecondaryButton className="px-3 ml-4 min-w-44 w-auto bg-white whitespace-nowrap">
                            Download PDF
                          </SecondaryButton>
                        </div>
                      );
                    }}
                  </PDFDownloadLink>
                ) : (
                  <SecondaryButton
                    className="px-3 ml-4 min-w-44 w-auto bg-white whitespace-nowrap"
                    onClick={handleDownloadClick}
                  >
                    Download PDF
                  </SecondaryButton>
                )}
              </div>
            </div>

            <div className="card p-4">
              <div className="row w-100">
                <div className="col-12 col-lg-8 mb-8">
                  {isLoading ? (
                    <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
                  ) : (
                    <div className=" overflow-x-auto w-100">
                      <div className="flex" style={{ minWidth: '600px' }}>
                        <MarketTable data={combinedData?.Flat} type="Flats" categories={flatCategories} />
                        <MarketTable data={combinedData?.House} type="Houses" categories={houseCategories} />
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-12 col-lg-4 ">
                  {combinedData && property_type ? (
                    <SummaryStatistics
                      property_type={property_type}
                      number_of_bedrooms={number_of_bedrooms}
                      combinedData={combinedData}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {combinedData && (
        <>
          <div className="mb-2.5 flex flex-col lg:flex-row items-center justify-between">
            <div className="fs-20 text-black">Rents paid</div>
            <div className="flex items-center justify-center pr-4">
              <div className="inline-flex items-center ml-4">
                District ({district_code})
                <div
                  className="w-8 ml-2 h-5 border-2 border-white"
                  style={{ backgroundColor: '#3571E5', borderRadius: '6px' }}
                ></div>
              </div>
              <div className="inline-flex items-center ml-4">
                Area ({area_code})
                <div
                  className="w-8 ml-2 h-5 border-2 border-white"
                  style={{ backgroundColor: '#FF4560', borderRadius: '6px' }}
                ></div>
              </div>
            </div>
          </div>
          <div className="card p-2">
            <div className="w-100">
              {isLoading ? (
                <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
              ) : (
                <div>
                  <div className="mb-10">
                    <div className="flex flex-col lg:flex-row overflow-x-auto w-100">
                      <div>
                        <RentsTable data={combinedData?.Flat} type="Flats" categories={flatCategories} />
                      </div>
                      <div>
                        <RentsTable data={combinedData?.House} type="Houses" categories={houseCategories} />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row w-full px-4">
                    <RentsPaidChartWrapper
                      combinedData={combinedData}
                      district_code={district_code}
                      area_code={area_code}
                      renderImage={(img, quartile) => updateRentsPaidChartImage(quartile, img)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {rentalMarketDistrict && rentalMarketArea && (
        <>
          <div className="mb-2.5 flex flex-col lg:flex-row items-center justify-between">
            <div className="fs-20 text-black">Depth of the market</div>
            <div className="flex items-center justify-center pr-4">
              <div className="inline-flex items-center ml-4">
                District ({district_code})
                <div
                  className="w-8 ml-2 h-5 border-2 border-white"
                  style={{ backgroundColor: '#3571E5', borderRadius: '6px' }}
                ></div>
              </div>
              <div className="inline-flex items-center ml-4">
                Area ({area_code})
                <div
                  className="w-8 ml-2 h-5 border-2 border-white"
                  style={{ backgroundColor: '#FF4560', borderRadius: '6px' }}
                ></div>
              </div>
            </div>
          </div>

          <div className="card p-2">
            <div className="flex w-100">
              {isLoading ? (
                <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
              ) : (
                <DepthOfMarketTable
                  property_type={property_type}
                  number_of_bedrooms={number_of_bedrooms}
                  district_code={district_code}
                  area_code={area_code}
                  district={rentalMarketDistrict}
                  area={rentalMarketArea}
                  renderImage={(img) => updateChartImage('depthOfMarketChartImg', img)}
                />
              )}
            </div>
          </div>
        </>
      )}

      {employmentCategories && (
        <>
          <div className="mb-2.5 flex flex-col lg:flex-row items-center justify-between">
            <div className="fs-20 text-black">Renter incomes, employment and affordability</div>
            <div className="flex items-center justify-center pr-4">
              <div className="inline-flex items-center ml-4">
                District ({district_code})
                <div
                  className="w-8 ml-2 h-5 border-2 border-white"
                  style={{ backgroundColor: '#3571E5', borderRadius: '6px' }}
                ></div>
              </div>
              <div className="inline-flex items-center ml-4">
                Area ({area_code})
                <div
                  className="w-8 ml-2 h-5 border-2 border-white"
                  style={{ backgroundColor: '#FF4560', borderRadius: '6px' }}
                ></div>
              </div>
            </div>
          </div>

          <div className="w-100 ">
            <div className="card p-2 flex w-100">
              {employmentStatusDataLoading ? (
                <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
              ) : (
                <EmploymentData
                  district_code={district_code}
                  area_code={area_code}
                  renderImage={(img) => updateChartImage('renterIncomesChartImg', img)}
                  categories={employmentCategories}
                  districtData={employmentDistrictData}
                  areaData={employmentAreaData}
                />
              )}
            </div>
          </div>
        </>
      )}

      <div className="row mb-8">
        {householdCategories && (
          <div className="col-12 col-lg-6 mb-8">
            <h4 className="mb-2.5 ml-2 fs-20 text-black"> Household profile – type </h4>
            <div className="card p-2 flex w-100">
              {householdDataLoading ? (
                <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
              ) : (
                <HouseholdData
                  district_code={district_code}
                  area_code={area_code}
                  renderImage={(img) => updateChartImage('householdProfileChartImg', img)}
                  categories={householdCategories}
                  districtData={householdDistrictData}
                  areaData={householdAreaData}
                />
              )}
            </div>
          </div>
        )}

        {tenureCategories && (
          <div className="col-12 col-lg-6">
            <h4 className="mb-2.5 ml-2 fs-20 text-black"> Tenure profile – all households </h4>
            <div>
              <div className="card p-2 flex w-100">
                {tenureDataLoading ? (
                  <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
                ) : (
                  <TenureData
                    district_code={district_code}
                    area_code={area_code}
                    renderImage={(img) => updateChartImage('tenureProfileChartImg', img)}
                    categories={tenureCategories}
                    districtData={tenureDistrictData}
                    areaData={tenureAreaData}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(RentalMarketReport);
