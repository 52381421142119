import React from 'react';

import InfoPopover from '../../Dashboard/Components/InfoPopover';
import { ReactComponent as LocationSVG } from 'icons/custom/location.svg';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

import { useQuery } from '@tanstack/react-query';
import { getPropertyDemographicsParameters } from '../Helpers/Helpers';
import { getDistrictPlanningApplications } from '../../../../api/planning';
import RadialChart from './RadialChart';
import DonutChart from './DonutChart';
import { apiHouseholdPercentages } from 'api/avm';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const PropertyDetails = ({ property, demographicsData }) => {
  const { data: planningApplicationsData, isLoading: planningApplicationsLoading } = useQuery(
    ['planning-applications', property.property_index_data.district_code],
    ({ signal }) => getDistrictPlanningApplications({ district: property.property_index_data.district_code }, signal),
    {
      enabled: !!property?.property_index_data?.district_code,
    },
  );

  const { district_code } = property.property_index_data;

  const { data: tenureData = [], isSuccess: householdDataLoaded } = useQuery(
    ['districtHouseholdData', { district: district_code }],
    ({ signal }) => apiHouseholdPercentages({ district: district_code }, signal),
  );

  const uniqueTenureData = tenureData.reduce((acc, item) => {
    if (!acc.some((entry) => entry.household_group === item.household_group)) {
      acc.push(item);
    }
    return acc;
  }, []);

  const districtData = uniqueTenureData.map((item) => ({
    name: item.household_group,
    value: Number((item.percentage_by_district || 0).toFixed(2)),
  }));

  const propertyDemographicsParameters = getPropertyDemographicsParameters(demographicsData);

  return (
    <>
      <h4 className="mb-2.5 ml-2 fs-20 text-black"> Risk and Demographics </h4>
      <div className="card p-4">
        <div className="row h-full overflow-hidden">
          <div className="col-5"></div>
          <div className="col-12">
            <div className="flex flex-row">
              <div className="mr-3 w-4">
                <LocationSVG />
              </div>
              <div className="font-medium	text-2xl text-black pb-6">{property?.address}</div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-xl-8">
                <div className="row">
                  {propertyDemographicsParameters.map((parameter, index) => (
                    <div key={index} className="col-lg-6 col-xl-6">
                      <div className="font-medium	text-base	mb-2 flex" style={{ color: '#747B8A' }}>
                        <div className="mr-2">{parameter.name}</div>
                        <InfoPopover
                          placement="top"
                          title={parameter.popupTitle}
                          description={parameter.popupDescription}
                        />
                      </div>
                      <div
                        className="flex flex-row font-medium text-base text-black items-center pb-2 mb-3"
                        style={{ borderBottom: '1px solid #F0F0F0' }}
                      >
                        <div className="w-6	mr-2.5">{parameter.icon}</div>

                        {parameter.value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-6 col-xl-4">
                <div>
                  <div className="mb-6">
                    {planningApplicationsLoading ? (
                      <LoaderSvg className="animate-spin h-8 w-8 text-white ml-4" />
                    ) : planningApplicationsData.count && planningApplicationsData.granted ? (
                      <div className="card p-1 pt-3" style={{ boxShadow: '0px 4px 10px 2px rgb(0 0 0 / 10%)' }}>
                        <h4 className="mb-2 text-base text-center fs-16" style={{ color: '#747B8A' }}>
                          Planning Applications Risk in {property.property_index_data.district_code}
                        </h4>

                        <div className="w-96 lg:w-80 xl:w-96 mx-auto">
                          <RadialChart
                            value={(
                              (1 - planningApplicationsData.granted / planningApplicationsData.count) *
                              100
                            ).toFixed()}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div
                    className="card flex p-6 justify-center"
                    style={{ boxShadow: '0px 4px 10px 2px rgb(0 0 0 / 10%)' }}
                  >
                    {householdDataLoaded ? <DonutChart chartData={districtData} /> : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(PropertyDetails);
