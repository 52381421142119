import { useCallback, useEffect, useState } from 'react';
import { searchSuperIndicesCSV, searchSuperIndicesData } from '../../../../api/superindexes';
import { DebounceInput } from 'react-debounce-input';
import debounce from 'lodash.debounce';
import Select from 'react-select';
import axios from 'axios';

import BenchMarkTable from '../../Dashboard/Tables/BenchmarkTable';
import { customSelectStyles, numberOfBedroomsOptions, propertyTypeOptions } from '../../../../utils/constants';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { ReactComponent as ResetFiltersSvg } from 'icons/custom/resetfilters.svg';
import { ReactComponent as FilterSvg } from 'icons/custom/filter.svg';
import { ReactComponent as DownloadSvg } from 'icons/custom/download.svg';

import errorImg from 'icons/custom/error.png';
import emptyImg from 'icons/custom/empty.png';
import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';

import numeral from 'numeral';
import 'numeral/locales/en-gb';
import StatusModal from '../../Dashboard/Modals/StatusModal';
import RangeSliderItem from '../../Dashboard/Components/RangeSliderItem';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const SearchDataTable = ({ updateSearchTableData }) => {
  const [searchTableData, setSearchTableData] = useState([]);
  const [filterData, setFilterData] = useState({
    post_town: 'london',
    num_of_bedrooms: '2',
    property_type: 'F',
    order_by: 'Chg_5Y',
    offset: 0,
    desc: true,
    ranges: {
      min_price: 200000,
      max_price: 1000000,
      min_rental_price: 500,
      max_rental_price: 6000,
      min_rental_yield: 0,
      max_rental_yield: 7,
    },
  });
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [controlledPageIndex, setControlledPageIndex] = useState(0);
  const [showGeneralError, setShowGeneralError] = useState(false);
  const [showLimitError, setShowLimitError] = useState(false);
  const [showEmptyResultError, setShowEmptyResultError] = useState(false);
  const [statusModalContent, setStatusModalContent] = useState({
    type: '',
    description: '',
  });

  const [priceRange, setPriceRange] = useState([200000, 1000000]);
  const [rentPriceRange, setRentPriceRange] = useState([500, 6000]);
  const [rentYieldRange, setRentYieldRange] = useState([0, 7]);

  let cancelToken;
  const getSearchData = async (filter) => {
    setLoading(true);
    setShowGeneralError(false);
    setShowLimitError(false);
    if (cancelToken) {
      cancelToken.cancel('Operation cancelled due to new Request');
    }
    cancelToken = axios.CancelToken.source();
    try {
      let num_of_bedrooms = parseInt(filter?.num_of_bedrooms);
      const searchResponse = await searchSuperIndicesData(
        {
          offset: filter?.offset,
          limit: 10,
          area_code: filter?.area_code?.toUpperCase(),
          district: filter?.district?.toUpperCase(),
          property_type: filter?.property_type ? [filter?.property_type] : undefined,
          num_of_bedrooms: num_of_bedrooms ? [num_of_bedrooms] : undefined,
          order_by: filter?.order_by,
          post_town: filter?.post_town?.toUpperCase(),
          ascending: !filter?.desc,
          ...filter.ranges,
          min_rental_yield: filter?.ranges?.min_rental_yield ? filter?.ranges?.min_rental_yield / 100 : undefined,
          max_rental_yield: filter?.ranges?.max_rental_yield
            ? filter?.ranges?.max_rental_yield / 100
            : undefined || 0.09,
        },
        { cancelToken: cancelToken.token },
      );

      if (searchResponse && searchResponse.data) {
        setSearchTableData(searchResponse?.data?.data);
        updateSearchTableData(searchResponse?.data?.data);
        setPageCount(Math.ceil(searchResponse?.data?.total_count / 10));
      }

      if (searchResponse?.data?.data?.length === 0) {
        setShowEmptyResultError(true);
      } else {
        setShowEmptyResultError(false);
      }

      setLoading(false);
    } catch (error) {
      setShowEmptyResultError(false);
      if (error.code !== 'ERR_CANCELED') {
        setSearchTableData([]);
        setLoading(false);
        if (error?.response?.data?.message === 'rate limit exceeded') {
          setShowLimitError(true);
        } else {
          setShowGeneralError(true);
        }
      }
    }
  };

  const debouncedChangeHandler = useCallback(debounce(getSearchData, 200), []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const formData = { ...filterData };
    if (fieldValue !== filterData[fieldName]) {
      formData[fieldName] = fieldValue;
      setFilterData(formData);
      setControlledPageIndex(0);
    }
  };

  const handleDropdownFilterFormChange = (item, selectField) => {
    const fieldName = selectField;
    const fieldValue = item.value;
    const formData = { ...filterData };
    if (fieldValue !== filterData[fieldName]) {
      formData[fieldName] = fieldValue;
      setFilterData(formData);
      setControlledPageIndex(0);
    }
  };

  const resetTableFilters = function () {
    const formData = {
      order_by: 'Chg_5Y',
      offset: 0,
      desc: true,
      ranges: {
        min_price: 200000,
        max_price: 1000000,
        min_rental_price: 500,
        max_rental_price: 6000,
        min_rental_yield: 0,
        max_rental_yield: 7,
      },
    };
    setPriceRange([200000, 1000000]);
    setRentPriceRange([500, 6000]);
    setRentYieldRange([0, 7]);

    setFilterData(formData);
    debouncedChangeHandler(formData);
    setControlledPageIndex(0);
  };

  const fetchData = useCallback(
    ({ pageIndex, sortBy }) => {
      let formData = { ...filterData };
      formData.offset = 10 * pageIndex || 0;

      if (sortBy && sortBy.length) {
        formData.desc = sortBy[0].desc;
        formData.order_by = sortBy[0].id;
      }

      setControlledPageIndex(pageIndex);
      setFilterData(formData);
      debouncedChangeHandler(formData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterData],
  );

  const applyFilters = () => {
    let formData = { ...filterData };
    formData.ranges = {
      min_price: priceRange[0],
      max_price: priceRange[1],
      min_rental_price: rentPriceRange[0],
      max_rental_price: rentPriceRange[1],
      min_rental_yield: rentYieldRange[0],
      max_rental_yield: rentYieldRange[1],
    };

    setFilterData(formData);
    debouncedChangeHandler(formData);
  };

  const downloadCSV = async () => {
    setLoading(true);
    if (cancelToken) {
      cancelToken.cancel('Operation cancelled due to new Request');
    }
    cancelToken = axios.CancelToken.source();
    try {
      let num_of_bedrooms = parseInt(filterData?.num_of_bedrooms);
      const searchResponseCSV = await searchSuperIndicesCSV(
        {
          offset: filterData?.offset,
          limit: 100,
          area_code: filterData?.area_code?.toUpperCase(),
          district: filterData?.district?.toUpperCase(),
          property_type: filterData?.property_type ? [filterData?.property_type] : undefined,
          num_of_bedrooms: num_of_bedrooms ? [num_of_bedrooms] : undefined,
          order_by: filterData?.order_by,
          post_town: filterData?.post_town?.toUpperCase(),
          ascending: !filterData?.desc,
          ...filterData.ranges,
          min_rental_yield: filterData?.ranges?.min_rental_yield
            ? filterData?.ranges?.min_rental_yield / 100
            : undefined,
          max_rental_yield: filterData?.ranges?.max_rental_yield
            ? filterData?.ranges?.max_rental_yield / 100
            : undefined || 0.09,
        },
        { cancelToken: cancelToken.token },
      );

      if (searchResponseCSV) {
        const blob = new Blob([searchResponseCSV], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data.csv';
        a.click();
        URL.revokeObjectURL(url);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'You have reached the daily download limit. Please try again later',
      });
    }
  };

  useEffect(() => {
    debouncedChangeHandler(filterData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StatusModal
        setShowModal={() => setStatusModalContent({ show: false })}
        showModal={statusModalContent.show}
        content={statusModalContent}
      />

      <BenchMarkTable
        pageCount={pageCount}
        pageIndex={controlledPageIndex}
        sampleTableData={searchTableData}
        filtersActive={false}
        fetchData={fetchData}
        loading={loading}
      >
        <div>
          <div className="card-header border-0 flex justify-between">
            <h4 className="mb-0 fs-20 text-black">Top area based benchmarks by expected returns</h4>
            <div className="flex">
              <div className="w-12 mt-7">{loading && <LoaderSvg className="animate-spin h-8 w-8 text-white" />}</div>

              <PrimaryButton className="w-36 mt-6 mr-3 mb-2" onClick={() => downloadCSV()}>
                <DownloadSvg className="mr-1.5" />
                Download CSV
              </PrimaryButton>
            </div>
          </div>
          <div className="flex flex-wrap w-100 mt-3 pl-7 pr-7">
            <div className="w-36 flex flex-column mb-3">
              <label className="font-medium text-black text-xs"> Location </label>
              <DebounceInput
                className="px-2.5 h-10 py-2 bg-white rounded-md mr-3 mb-2 border text-xs"
                placeholder="e.g. London"
                onChange={handleFilterFormChange}
                value={filterData.post_town || ''}
                debounceTimeout={500}
                name="post_town"
              />
            </div>
            <div className="w-36 flex flex-column mb-3">
              <label className="font-medium text-black text-xs"> Number of Bedrooms </label>
              <Select
                className="mr-3 mb-2 h-10 text-xs"
                defaultValue={[numberOfBedroomsOptions[3]]}
                placeholder="Number of Bedrooms"
                value={
                  numberOfBedroomsOptions.find(({ value }) => value === filterData.num_of_bedrooms) ||
                  numberOfBedroomsOptions[0]
                }
                onChange={(item) => handleDropdownFilterFormChange(item, 'num_of_bedrooms')}
                styles={customSelectStyles}
                options={numberOfBedroomsOptions}
                isSearchable={false}
                name="num_of_bedrooms"
              />
            </div>
            <div className="w-36 flex flex-column mb-3">
              <label className="font-medium text-black text-xs"> Property Type </label>
              <Select
                className="mr-3 mb-2 h-10 text-xs"
                placeholder="Property Type"
                defaultValue={[propertyTypeOptions[2]]}
                value={
                  propertyTypeOptions.find(({ value }) => value === filterData.property_type) || propertyTypeOptions[0]
                }
                onChange={(item) => handleDropdownFilterFormChange(item, 'property_type')}
                styles={customSelectStyles}
                options={propertyTypeOptions}
                isSearchable={true}
                name="property_type"
              />
            </div>
            <div className="w-36 flex flex-column mb-3">
              <label className="font-medium text-black text-xs"> Area Code </label>
              <DebounceInput
                className="px-2.5 h-10 py-2 bg-white rounded-md mr-3 mb-2 border text-xs"
                placeholder="e.g. SW"
                onChange={handleFilterFormChange}
                value={filterData.area_code || ''}
                debounceTimeout={500}
                name="area_code"
              />
            </div>
            <div className="w-36 flex flex-column mb-3">
              <label className="font-medium text-black text-xs"> District Code </label>
              <DebounceInput
                className="px-2.5 h-10 py-2 bg-white rounded-md mr-3 mb-2 border text-xs"
                placeholder="e.g. SW6"
                onChange={handleFilterFormChange}
                value={filterData.district || ''}
                debounceTimeout={500}
                name="district"
              />
            </div>

            <div className="w-52 flex flex-column mr-5">
              <label className="font-medium text-black text-xs"> Price Range </label>
              <RangeSliderItem
                title={''}
                min={0}
                max={10000000}
                step={10000}
                range={priceRange}
                updateRange={setPriceRange}
                unit={'£'}
              ></RangeSliderItem>
            </div>

            <div className="w-52 flex flex-column mr-5">
              <label className="font-medium text-black text-xs"> Monthly Rent Price Range </label>
              <RangeSliderItem
                title={''}
                min={0}
                max={10000}
                step={10}
                range={rentPriceRange}
                updateRange={setRentPriceRange}
                unit={'£'}
              ></RangeSliderItem>
            </div>

            <div className="w-52 flex flex-column">
              <label className="font-medium text-black text-xs"> Rental Yield Range </label>
              <RangeSliderItem
                title={''}
                min={0}
                max={15}
                step={1}
                range={rentYieldRange}
                updateRange={setRentYieldRange}
                unit={'%'}
              ></RangeSliderItem>
            </div>
          </div>
          <div className="w-100 mb-2">
            <div className="flex flex-wrap mx-4 border-t">
              <PrimaryButton className="ml-auto w-36 mt-3 mr-3 mb-2" onClick={applyFilters}>
                <FilterSvg className="mr-1.5" />
                Apply Filters
              </PrimaryButton>

              <SecondaryButton className="w-36 mt-3 mb-2" onClick={resetTableFilters}>
                <ResetFiltersSvg className="mr-1.5" />
                Reset Filters
              </SecondaryButton>
            </div>
          </div>

          {!loading ? (
            <>
              {showGeneralError && !showLimitError && !showEmptyResultError && (
                <div className="items-center flex flex-column justify-center my-5">
                  <img className="h-52" src={errorImg} alt="error" />
                  <div className="text-lg text-black font-semibold text-center my-2"> An error has occurred </div>
                  <div className="text-xs font-normal w-80 text-center">Please try again later.</div>
                </div>
              )}

              {!showGeneralError && showLimitError && !showEmptyResultError && (
                <div className="items-center flex flex-column justify-center my-5">
                  <img className="h-52" src={errorImg} alt="error" />
                  <div className="text-lg text-black font-semibold text-center my-2"> Daily Limit Reached </div>
                  <div className="text-xs font-normal w-80 text-center">
                    Sorry, but you've reached your daily limit for this action.
                  </div>
                </div>
              )}

              {!showGeneralError && !showLimitError && showEmptyResultError && (
                <div className="items-center flex flex-column justify-center my-5">
                  <img className="h-52" src={emptyImg} alt="error" />
                  <div className="text-lg text-black font-semibold text-center my-2"> No Results Found </div>
                  <div className="text-xs font-normal w-80 text-center">
                    Nothing was found based on your filter settings. Please change the filter settings.
                  </div>
                </div>
              )}
            </>
          ) : (
            ''
          )}
        </div>
      </BenchMarkTable>
    </>
  );
};

export default SearchDataTable;
