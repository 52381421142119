import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

const PieChartAnalytics = ({ pieChartData }) => {
  const series = useMemo(() => pieChartData.map((item) => Number(item.value) || 0), [pieChartData]);
  const labels = useMemo(() => pieChartData.map((item) => item.name), [pieChartData]);

  const options = useMemo(
    () => ({
      chart: {
        type: 'donut',
      },
      plotOptions: {
        pie: {
          donut: {
            size: '50%',
          },
        },
      },
      colors: ['#0A2E6C', '#3571E5', '#3571e599', '#747B8A'],
      labels: labels,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [series, labels],
  );

  return (
    <div className="donut-chart">
      {series.length > 0 ? (
        <ReactApexChart options={options} series={series} type="donut" width="340" />
      ) : (
        <LoaderSvg className="animate-spin h-8 w-8 text-white ml-4" />
      )}
    </div>
  );
};

export default PieChartAnalytics;
