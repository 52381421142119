import React, { useEffect, useState, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const BarChart = ({
  categories = [],
  districtData = {},
  areaData = {},
  district_code = '',
  area_code = '',
  showLabels = false,
  showRate = true,
  renderImage = null,
  orientation = 'vertical',
}) => {
  const [chartInstance, setChartInstance] = useState(null);

  const districtValues = useMemo(() => Object.values(districtData), [districtData]);
  const areaValues = useMemo(() => Object.values(areaData), [areaData]);

  const chartHeight = orientation !== 'horizontal' ? 350 : 550;

  const series = useMemo(
    () => [
      {
        name: `District (${district_code})`,
        data: showRate ? districtValues.map((val) => val * 100) : districtValues,
      },
      {
        name: `Area (${area_code})`,
        data: showRate ? areaValues.map((val) => val * 100) : areaValues,
      },
    ],
    [districtValues, areaValues, district_code, area_code, showRate],
  );

  const options = useMemo(
    () => ({
      chart: {
        height: chartHeight,
        type: 'bar',
        toolbar: { show: true },
        animations: { enabled: false },
        events: { mounted: (chart) => setChartInstance(chart) },
      },
      fill: { type: 'solid', colors: ['#C3D4F7', '#FFE2E7'] },
      legend: { show: true, showForSingleSeries: true },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: orientation === 'horizontal',
          dataLabels: { position: 'top', orientation },
        },
      },
      dataLabels: {
        enabled: showLabels,
        offsetX: 0,
        offsetY: 6,
        formatter: showRate ? (val) => numeral(val / 100).format('%') : (val) => numeral(val).format(),
        style: { fontSize: '10px', colors: ['#3571E5', '#FF4560'] },
      },
      stroke: { width: 1, colors: ['#3571E5', '#FF4560'] },
      grid: { xaxis: { lines: { show: false } }, yaxis: { lines: { show: true } } },
      xaxis: { categories },
      yaxis: {
        labels: {
          formatter: (val) =>
            orientation !== 'horizontal' ? (showRate ? `${numeral(val).format('0')}%` : numeral(val).format()) : val,
        },
      },
      colors: ['#3571E5', '#FF4560'],
    }),
    [chartHeight, orientation, showLabels, showRate, categories],
  );

  useEffect(() => {
    if (chartInstance) {
      const timer = setTimeout(() => {
        chartInstance.dataURI().then(({ imgURI }) => renderImage?.(imgURI));
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [chartInstance, renderImage]);

  if (!categories.length || (!districtValues.length && !areaValues.length)) {
    return <div>No data available</div>;
  }

  return (
    <div
      id="chart3"
      className="bar-chart base-bar-chart pb-3"
      style={{
        width: orientation === 'horizontal' ? '400px' : '100%',
      }}
    >
      <ReactApexChart options={options} series={series} type="bar" height={chartHeight} />
    </div>
  );
};

export default React.memo(BarChart);
