import BarChart from './BarChart';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const HouseholdData = ({ district_code, area_code, renderImage, categories, districtData, areaData }) => {
  return (
    <>
      <div className="w-100">
        <div className="p-4">
          {districtData ? (
            <BarChart
              categories={categories}
              districtData={districtData}
              areaData={areaData}
              district_code={district_code}
              area_code={area_code}
              showLabels={true}
              renderImage={renderImage}
            />
          ) : (
            ''
          )}
        </div>

        <div className="flex p-4">
          <div>
            <table className="border-collapse ">
              <thead>
                <tr className="text-left">
                  <th className="font-medium p-2 text-md text-black">Range</th>
                  <th className="font-medium p-2 text-md text-black border-l">District (%)</th>
                  <th className="font-medium p-2 text-md text-black border-l">Area (%)</th>
                </tr>
              </thead>
              <tbody>
                {categories.map((group, index) => (
                  <tr key={index}>
                    <td className="p-2">{group}</td>
                    <td className="p-2 border-l border-gray-300 text-center" style={{ color: '#3571E5' }}>
                      {(districtData[group] * 100).toFixed(2)}%
                    </td>
                    <td className="p-2 border-l border-gray-300 text-center" style={{ color: '#FF4560' }}>
                      {(areaData[group] * 100).toFixed(2)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default HouseholdData;
