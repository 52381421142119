import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#ccc',
    borderWidth: 1,
    marginBottom: 10,
    fontSize: 8,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCellHeader: {
    fontSize: 11,
  },
  tableCell: {
    flex: 1,
    textAlign: 'center',
    borderStyle: 'solid',
    borderColor: '#ccc',
    borderWidth: 1,
    padding: 5,
  },
});

const RentalDataTablePdf = ({ categories, districtData, areaData }) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <Text style={[styles.tableCell, styles.tableCellHeader]}>Range</Text>
        <Text style={[styles.tableCell, styles.tableCellHeader]}>District (%)</Text>
        <Text style={[styles.tableCell, styles.tableCellHeader]}>Area (%)</Text>
      </View>
      {categories.map((group, index) => (
        <View key={index} style={styles.tableRow}>
          <Text style={styles.tableCell}>{group}</Text>
          <Text style={styles.tableCell}>{(districtData[group] * 100).toFixed(2)}%</Text>
          <Text style={styles.tableCell}>{(areaData[group] * 100).toFixed(2)}%</Text>
        </View>
      ))}
    </View>
  );
};

export default RentalDataTablePdf;
