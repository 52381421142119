import { ReactComponent as PoundSvg } from 'icons/custom/pound-sign.svg';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const propertyTypeMapping = {
  F: 'Flat',
  T: 'House',
  S: 'House',
  D: 'House',
};

const SummaryStatistics = ({ property_type, number_of_bedrooms, combinedData }) => {
  const mappedType = propertyTypeMapping[property_type] || property_type;

  let adjustedBedrooms = Number(number_of_bedrooms);

  if (mappedType === 'Flat' && number_of_bedrooms > 3) {
    adjustedBedrooms = 3;
  } else if (mappedType === 'House' && number_of_bedrooms > 4) {
    adjustedBedrooms = 4;
  }

  const dataPoint = combinedData[mappedType]?.[`${adjustedBedrooms} Bed`] || {};

  const rentalBandRange = dataPoint.mostActiveRentalBand?.district
    ? (() => {
        const match = dataPoint.mostActiveRentalBand?.district.match(/\((\d+),\s*(\d+)\]/);

        if (match) {
          const lowerBound = numeral(match[1]).format();
          const upperBound = numeral(match[2]).format();

          return `${lowerBound} - ${upperBound}`;
        }
        return ``;
      })()
    : ``;

  return (
    <div>
      <h5 className="text-lg my-2">Summary Statistics</h5>

      <div className="my-2 py-2 border-b text-md font-medium">
        <div className="font-medium">Avg. Rent Paid</div>
        <div className="flex items-center text-black">
          <PoundSvg className="w-8 pr-2" />
          {numeral(dataPoint.avgRentPaid12m?.district).format() || '-'}
        </div>
      </div>

      <div className="my-2 py-2 border-b text-md font-medium">
        <div className="font-medium">Avg. Rent top 25% of lets</div>
        <div className="flex items-center text-black">
          <PoundSvg className="w-8 pr-2" />
          {numeral(dataPoint.avgRentTop25?.district).format() || '-'}
        </div>
      </div>

      <div className="my-2 py-2 border-b text-md font-medium">
        <div className="font-medium">Most active rental band</div>
        <div className="flex items-center text-black">
          <PoundSvg className="w-8 pr-2" />
          {rentalBandRange}
        </div>
      </div>

      <div className="my-2 py-2 border-b text-md font-medium">
        <div className="font-medium">Most prominent age band</div>
        <div className="flex items-center text-black">
          <PoundSvg className="w-8 pr-2" /> {'-'}
        </div>
      </div>

      <div className="my-2 py-2 border-b text-md font-medium">
        <div className="font-medium">Median gross income of renters</div>
        <div className="flex items-center text-black">
          <PoundSvg className="w-8 pr-2" /> {'-'}
        </div>
      </div>
    </div>
  );
};

export default SummaryStatistics;
