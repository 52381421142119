import axiosInstance from '../services/AxiosInstance';
import Cookies from 'js-cookie';

import moment from 'moment';

// TODO: remove after property analytics page is updated
export function getRiskFreeRate(data = {}) {
  return axiosInstance.post(`/avm/risk_free_rate`, data, {
    headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
  });
}

export const apiSearchSchoolData = async (data, signal) => {
  try {
    const response = await axiosInstance.post(`/avm/schools`, data, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response?.data?.data;
    } else {
      throw new Error('get schools data failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiRentalMarketDistrict = async (data, signal) => {
  try {
    const response = await axiosInstance.post(`/avm/rental_market_district`, data, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response?.data?.data;
    } else {
      throw new Error('get rental_market_district data failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiRentalMarketArea = async (data, signal) => {
  try {
    const response = await axiosInstance.post(`/avm/rental_market_area`, data, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response?.data?.data;
    } else {
      throw new Error('get rental_market_area data failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiEmployementStatusPercentages = async (data, signal) => {
  try {
    const response = await axiosInstance.post(`/avm/employement_status_percentages`, data, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response?.data?.data;
    } else {
      throw new Error('get employement_status_percentages data failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiTenurePercentagesHousehold = async (data, signal) => {
  try {
    const response = await axiosInstance.post(`/avm/tenure_percentages_household`, data, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response?.data?.data;
    } else {
      throw new Error('get tenure_percentages_household data failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiHouseholdPercentages = async (data, signal) => {
  try {
    const response = await axiosInstance.post(`/avm/household_percentages`, data, {
      signal,
      headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
    });
    if (response.status === 200) {
      return response?.data?.data;
    } else {
      throw new Error('get household_percentages data failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiGetRiskFreeRate = async (signal) => {
  try {
    const response = await axiosInstance.post(
      `/avm/risk_free_rate`,
      {},
      {
        signal,
        headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
      },
    );
    if (response.status === 200) {
      return response?.data?.data[0]?.close / 100;
    } else {
      throw new Error('get cpih index data failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiGetCPIHPMonthlyData = async (signal) => {
  try {
    const response = await axiosInstance.post(
      `/avm/cpih`,
      {},
      {
        signal,
        headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
      },
    );
    if (response.status === 200) {
      return response.data.data
        .map((item) => ({
          timestamp: moment(item.date).startOf('month').format('YYYY-MM-DD'),
          value: item.cpih_annual_index_rate,
        }))
        .reduce((uniqueArr, obj) => {
          if (!uniqueArr.some((item) => item.timestamp === obj.timestamp)) {
            uniqueArr.push(obj);
          }
          return uniqueArr;
        }, []);
    } else {
      throw new Error('get cpih index data failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiGetBankRateMonthlyData = async (signal) => {
  try {
    const response = await axiosInstance.post(
      `/avm/bank_rate`,
      {},
      {
        signal,
        headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
      },
    );
    if (response.status === 200) {
      return response.data.data
        .map((item) => ({
          timestamp: moment(item.date).startOf('month').format('YYYY-MM-DD'),
          value: item.monthly_average_yield,
        }))
        .reduce((uniqueArr, obj) => {
          if (!uniqueArr.some((item) => item.timestamp === obj.timestamp)) {
            uniqueArr.push(obj);
          }
          return uniqueArr;
        }, []);
    } else {
      throw new Error('get bank_rate index data failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiGetUnemploymentMonthlyData = async (signal) => {
  try {
    const response = await axiosInstance.post(
      `/avm/unemployment`,
      {},
      {
        signal,
        headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
      },
    );
    if (response.status === 200) {
      return response.data.data
        .map((item) => ({
          timestamp: moment(item.date).startOf('month').format('YYYY-MM-DD'),
          value: item.unemployment_rate_16_and_older_seasonally_adjusted,
        }))
        .reduce((uniqueArr, obj) => {
          if (!uniqueArr.some((item) => item.timestamp === obj.timestamp)) {
            uniqueArr.push(obj);
          }
          return uniqueArr;
        }, []);
    } else {
      throw new Error('get unemployment index data failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiGetFTSEMonthlyData = async (signal) => {
  try {
    const response = await axiosInstance.post(
      `/avm/ftse`,
      {},
      {
        signal,
        headers: { Authorization: `Bearer ${Cookies.get('REalysepmt-token')}` },
      },
    );
    if (response.status === 200) {
      return response.data.data
        .map((item) => ({
          timestamp: moment(item.date).startOf('month').format('YYYY-MM-DD'),
          value: parseFloat(item.close),
        }))
        .reduce((uniqueArr, obj) => {
          if (!uniqueArr.some((item) => item.timestamp === obj.timestamp)) {
            uniqueArr.push(obj);
          }
          return uniqueArr;
        }, []);
    } else {
      throw new Error('get ftse index data failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
