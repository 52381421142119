import React, { useMemo } from 'react';
import { useState } from 'react';
import Select from 'react-select';
import PageHead from '../additionalModules/PageHead';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { apiGetPortfolioAnalyticsById } from '../../../api/portfolio';
import {
  formatKeyPerformanceObject,
  getStatisticalData,
  propertySVGs,
  seriesObjectToArray,
} from '../../../utils/helpers';
import PortfolioByPropertyTable from './Components/PortfolioByPropertyTable';
import moment from 'moment';
import { pow, sqrt } from 'mathjs';

import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import Error404 from 'jsx/pages/Error404';
import _ from 'lodash';
import { apiGetRentalIndexByParams } from '../../../api/rentalIndex';
import { apiGetRiskFreeRate } from '../../../api/avm';
import KeyPerformanceMetricsTable from '../Dashboard/Tables/KeyPerformanceMetricsTable';
import ItemVsIndexChart from '../Dashboard/Charts/ItemVsIndexChart';
import PieChartAnalytics from '../additionalModules/Analytics/PieChartAnalytics';
import PropertiesMap from './Components/PropertiesMap';
import RentCharts from './Components/RentCharts';
import Card from '../Dashboard/Components/Card';
import { useQueries, useQuery } from '@tanstack/react-query';
import { apiGetPremiumValue } from '../../../api/premiumvalue';
import { getTargetTime } from '../../../api/propertyIndex';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const propertyTypes = [
  {
    name: 'Flat',
    value: 0,
  },
  {
    name: 'Terraced',
    value: 0,
  },
  {
    name: 'Semi-Detached',
    value: 0,
  },
  {
    name: 'Detached',
    value: 0,
  },
];

function groupData(array, groupField) {
  var groups = {};
  for (var i = 0; i < array.length; i++) {
    var row = array[i];
    var groupValue = row[groupField];
    groups[groupValue] = groups[groupValue] || [];
    groups[groupValue].push(row);
  }
  return groups;
}

const getChangeBasedOnMonth = (propertyDataForecast, monthIndex, targetTime) => {
  const formattedTargetDate = moment(targetTime).startOf('month').format('YYYY-MM-DD');
  const formattedPrevDate = moment(targetTime).subtract(monthIndex, 'months').startOf('month').format('YYYY-MM-DD');
  const targetValue = propertyDataForecast?.[formattedTargetDate] ?? 0;
  const prevValue = propertyDataForecast?.[formattedPrevDate] ?? 0;
  const changeValue = (targetValue - prevValue) / prevValue;
  return numeral(changeValue).format('0.00%');
};

const formatPortfolioKeyPerformanceObject = (data, riskFreeRate, targetTime) => {
  const formattedCurrentDate = moment(targetTime).startOf('month').format('YYYY-MM-DD');
  const formattedBeginningDate = moment(targetTime).subtract(59, 'months').startOf('month').format('YYYY-MM-DD');
  const formattedEndDate = moment(targetTime).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
  const formattedStartOfYear = moment(targetTime).startOf('year').format('YYYY-MM-DD');

  if (data && data.portfolio_forecast) {
    const valueAtTargetTime = data?.portfolio_forecast[formattedCurrentDate];
    const beginningValue = data?.portfolio_forecast[formattedBeginningDate];
    const valueAtTheEnd = data?.portfolio_forecast[formattedEndDate];
    const valueAtStartOfYear = data?.portfolio_forecast[formattedStartOfYear];

    const { average, stdev } = getStatisticalData(data?.portfolio_forecast);

    const volatility = sqrt(12) * stdev;
    const cagr = pow(valueAtTheEnd / beginningValue, 1 / 5) - 1;

    const last12MonthReturns = 12 * average;
    const sharpe = (last12MonthReturns - riskFreeRate) / volatility;

    return {
      riskFreeRate: riskFreeRate,
      cumulativeReturn: (valueAtTheEnd - beginningValue) / beginningValue,
      cagr: cagr,
      sharpe: sharpe,
      oneMonthReturn: data.chg_1m,
      YTDReturns: (valueAtTargetTime - valueAtStartOfYear) / valueAtStartOfYear,
      last12MonthReturns: data.chg_1y,
      expected12MonthReturns: data.est_chg_1y,
      expected24MonthReturns: data.est_chg_2y,
      estimatedSaleValue: valueAtTargetTime,
      volatility: volatility,
    };
  }
};

const Analytics = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [benchmarkDropdown, setBenchmarkDropDown] = useState([]);
  const [portfolioGraphSeriesData, setPortfolioGraphSeriesData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [portfolioPM, setPortfolioPM] = useState({});
  const [benchmarkPM, setBenchmarkPM] = useState({});
  const [sampleStatsDataGroupedAndSorted, setSampleStatsDataGroupedAndSorted] = useState({});

  const { portfolioId } = useParams();

  const { data: targetTime, isSuccess: targetTimeLoaded } = useQuery(['targetTime'], ({ signal }) =>
    getTargetTime(signal),
  );

  const {
    data: analyticsData,
    isLoading: loading,
    isSuccess: portfolioLoaded,
    error,
  } = useQuery(['portfolioAnalyticsData', parseInt(portfolioId)], ({ signal }) =>
    apiGetPortfolioAnalyticsById(portfolioId, signal),
  );

  const { data: riskFreeRate = undefined, isSuccess: riskFreeRateLoaded } = useQuery(['riskFreeRate'], ({ signal }) =>
    apiGetRiskFreeRate(),
  );

  const rentalIndexQueries = useQueries({
    queries: (analyticsData?.property_analytics || []).map((property) => {
      return {
        queryKey: [
          'rentalIndex',
          property?.property_index_data?.district_code,
          property?.property_index_data?.property_type,
          property?.property_index_data?.number_of_bedrooms,
        ],
        queryFn: ({ signal }) =>
          apiGetRentalIndexByParams({
            districtCode: property?.property_index_data?.district_code,
            propertyType: property?.property_index_data?.property_type,
            numBedrooms: property?.property_index_data?.number_of_bedrooms,
            signal,
          }),
        enabled: portfolioLoaded && !!analyticsData?.property_analytics?.length,
      };
    }),
  });

  const rentalIndexQueriesFinished = useMemo(
    () => rentalIndexQueries.every((query) => query.isSuccess),
    [rentalIndexQueries],
  );

  const rentsByDistrict = useMemo(() => {
    if (rentalIndexQueriesFinished && portfolioLoaded && riskFreeRateLoaded && targetTimeLoaded) {
      const { property_analytics } = analyticsData;

      const rentsByDistrictData = [];
      for (const property of property_analytics) {
        const districtCode = property?.property_index_data?.district_code;
        const propertyType = property?.property_index_data?.property_type;
        const numBedrooms = property?.property_index_data?.number_of_bedrooms;
        let responseData = rentalIndexQueries.find(
          (query) => query.data.index_name === `${districtCode} ${propertyType} ${numBedrooms}`,
        );

        responseData = responseData ? responseData?.data : [];
        const formattedCurrentDate = moment(targetTime).startOf('month').format('YYYY-MM-DD');
        const rentValue = responseData?.forecast ? responseData?.forecast[formattedCurrentDate] : 0;
        rentsByDistrictData.push({
          identifier: `${districtCode}-${propertyType}-${numBedrooms}`,
          value: rentValue,
          forecast: responseData?.forecast || {},
        });
      }
      return rentsByDistrictData;
    } else {
      return [];
    }
  }, [
    analyticsData,
    portfolioLoaded,
    rentalIndexQueries,
    rentalIndexQueriesFinished,
    riskFreeRateLoaded,
    targetTime,
    targetTimeLoaded,
  ]);

  const premiumValueQueries = useQueries({
    queries: (analyticsData?.property_analytics || []).map((property) => {
      return {
        queryKey: [
          'premiumValue',
          `${property?.property_index_data?.post_town} ${property?.property_index_data?.district_code} ${property?.property_index_data?.property_type} ${property?.property_index_data?.number_of_bedrooms}`,
        ],
        queryFn: ({ signal }) =>
          apiGetPremiumValue(
            `${property?.property_index_data?.post_town} ${property?.property_index_data?.district_code} ${property?.property_index_data?.property_type} ${property?.property_index_data?.number_of_bedrooms}`,
            signal,
          ),
        enabled: portfolioLoaded && !!analyticsData?.property_analytics?.length,
      };
    }),
  });

  const premiumValueQueriesFinished = useMemo(
    () => premiumValueQueries.every((query) => query.isSuccess),
    [premiumValueQueries],
  );

  const premiumValuesByTypes = useMemo(() => {
    if (premiumValueQueriesFinished && portfolioLoaded && riskFreeRateLoaded && targetTimeLoaded) {
      const data = premiumValueQueries.map((query) => query.data);
      return data;
    }
  }, [portfolioLoaded, premiumValueQueries, premiumValueQueriesFinished, riskFreeRateLoaded, targetTimeLoaded]);

  const formattedPortfolioData = useMemo(() => {
    if (rentsByDistrict?.length && premiumValuesByTypes?.length) {
      const portfolioProperties = (analyticsData?.property_analytics || []).map((item) => {
        const formattedCurrentDate = moment(targetTime).startOf('month').format('YYYY-MM-DD');
        const benchmarkValue = item?.super_index_data?.forecast[formattedCurrentDate];

        const postTown = item?.property_index_data?.post_town;
        const districtCode = item?.property_index_data?.district_code;
        const propertyType = item?.property_index_data?.property_type;
        const numBedrooms = item?.property_index_data?.number_of_bedrooms;

        const premiumValue = premiumValuesByTypes.find(
          (item) => item?.group_name === `${postTown} ${districtCode} ${propertyType} ${numBedrooms}`,
        );

        item.weight_percentage = item.price / analyticsData.portfolio_value;
        item.monthly_income =
          rentsByDistrict.find((item) => item.identifier === `${districtCode}-${propertyType}-${numBedrooms}`)?.value ||
          undefined;

        item.annual_yield = (item.monthly_income * 12) / item.price;

        item.actualRent =
          item.override_rents && item.override_rents[formattedCurrentDate]
            ? numeral(item.override_rents[formattedCurrentDate]).format()
            : '-';

        item.actualRentalYield =
          item.override_rents && item.override_rents[formattedCurrentDate]
            ? numeral((item.override_rents[formattedCurrentDate] * 12) / item.price).format('0.00%')
            : '-';

        const yearsAfterLastTransaction = moment().diff(
          moment(item.last_transaction_date || item.property_index_data?.last_transaction_date, 'YYYY-MM-DD'),
          'years',
        );

        return {
          ...item,
          property_type: propertyType,
          id: item.id,
          location: item.address,
          uprn: item.uprn,
          value: numeral(item.price).format(),
          premiumValue: premiumValue ? numeral(premiumValue?.premium_properties_value).format() : '-',
          instant_purchase_via_realyse_price:
            item.instant_purchase_via_realyse_price &&
            Math.abs((item?.price - item?.instant_purchase_via_realyse_price) / item?.price) <= 0.2
              ? numeral(item.instant_purchase_via_realyse_price).format()
              : ' - ',
          instant_purchase_via_realyse_price_error_limit:
            Math.abs((item?.price - item?.instant_purchase_via_realyse_price) / item?.price) <= 0.2
              ? item.instant_purchase_via_realyse_price_error_limit
              : undefined,
          chg1m: getChangeBasedOnMonth(item.property_index_data?.forecast, 1, targetTime),
          chg3m: getChangeBasedOnMonth(item.property_index_data?.forecast, 3, targetTime),
          chg6m: getChangeBasedOnMonth(item.property_index_data?.forecast, 6, targetTime),
          chg1y: getChangeBasedOnMonth(item.property_index_data?.forecast, 12, targetTime),
          number_of_bedrooms: numBedrooms ?? '-',
          lastTransactionPrice:
            (item.last_transaction_price || item.property_index_data?.last_transaction_price) > 0
              ? numeral(item.last_transaction_price || item.property_index_data?.last_transaction_price).format()
              : '-',
          lastTransactionDate: moment(
            item.last_transaction_date || item.property_index_data?.last_transaction_date,
          ).isBefore(moment())
            ? item.last_transaction_date || item.property_index_data?.last_transaction_date?.split(/-/)?.join('-')
            : '-',
          highAccuracy: yearsAfterLastTransaction < 5 && yearsAfterLastTransaction >= 0,
          moMPriceChange: numeral(item.mom_price_change).format('0.00%'),
          weightPercentage: numeral(item.weight_percentage).format('0.00%'),
          indexRentEstimated: numeral(item.monthly_income).format(),
          rentalYield: item?.monthly_income ? numeral((item?.monthly_income / item?.price) * 12).format('0.00%') : '-',
          annualYield: numeral(item.annual_yield).format('0.00%'),
          propertyType: propertySVGs[item?.property_index_data?.property_type]?.name ?? '-',
          districtCode: districtCode ?? '-',
          benchmarkValue: benchmarkValue ? numeral(benchmarkValue).format() : ' - ',
        };
      });
      return portfolioProperties;
    } else {
      return [];
    }
  }, [rentsByDistrict, premiumValuesByTypes, analyticsData, targetTime]);

  useEffect(() => {
    if (formattedPortfolioData.length > 0) {
      setSampleStatsDataGroupedAndSorted(groupData(formattedPortfolioData, 'property_type'));
      groupPropertiesByType(formattedPortfolioData);
      generateDropdownData(formattedPortfolioData, analyticsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedPortfolioData.length]);

  const handleChange = async function (selectedOption) {
    setSelectedIndex(selectedOption);
    if (selectedOption && selectedOption.forecast) {
      const benchmarkKeyPerformance = formatKeyPerformanceObject(selectedOption, riskFreeRate, targetTime);
      setBenchmarkPM(benchmarkKeyPerformance);
    }
  };

  const generatePortfolioDetails = async (portfolioDetails, riskFreeRate, targetTime) => {
    const propertyKeyPerformance = formatPortfolioKeyPerformanceObject(portfolioDetails, riskFreeRate, targetTime);
    setPortfolioPM(propertyKeyPerformance);
    setPortfolioGraphSeriesData(seriesObjectToArray(portfolioDetails.portfolio_forecast, targetTime));
  };

  const generateDropdownData = (portfolioProperties, portfolioDetails) => {
    const dropdownData = _.uniqBy(portfolioProperties, (e) => e?.super_index_data?.index_name)
      .filter((property) => property.super_index_data)
      .map((property) => {
        let item = property.super_index_data;
        return {
          ...item,
          label: `${item.post_town}, District: ${item.district_code}, Type ${item.property_type}, Rooms: ${parseInt(
            item.number_of_bedrooms,
          )}`,
          value: `${item.index_name}`,
          id: `${item.index_name}`,
          post_town: item.post_town,
          district_code: item.district_code,
          property_type: item.property_type,
          number_of_bedrooms: parseInt(item.number_of_bedrooms),
        };
      });

    dropdownData.sort((a, b) => a.label.localeCompare(b.label));

    dropdownData.unshift({
      ...portfolioDetails,
      label: 'Portfolio Benchmark',
      value: 'portfolio_benchmark',
      id: 'portfolio_benchmark',
      forecast: portfolioDetails.portfolio_benchmark_forecast,
    });

    setBenchmarkDropDown(dropdownData);
    handleChange(dropdownData[0]);
  };

  const groupPropertiesByType = (properties) => {
    const propertyTypesList = propertyTypes.map((type) => {
      const count = properties.filter(
        (property) =>
          property?.property_index_data?.property_type.charAt(0).toUpperCase() === type.name.charAt(0).toUpperCase(),
      ).length;

      return { ...type, value: count };
    });

    setPieChartData(propertyTypesList.filter((item) => item.value > 0));
  };

  useEffect(() => {
    if (analyticsData && targetTime && riskFreeRate) {
      generatePortfolioDetails(analyticsData, riskFreeRate, targetTime);
    }
  }, [analyticsData, targetTime, riskFreeRate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return <Error404 />;
  }

  return (
    <>
      <PageHead activePage="Portfolio Analytics" pageName={analyticsData?.name} />
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-3 col-sm-3">
              <Card
                title={'Portfolio Value'}
                value={numeral(analyticsData?.portfolio_value).format()}
                loading={loading}
              />
            </div>
            <div className="col-xl-3 col-sm-3">
              <Card
                title={'Number of Units'}
                value={analyticsData?.property_analytics?.length || 0}
                loading={loading}
              />
            </div>
            <div className="col-xl-3 col-sm-3">
              <Card
                title={'Annualised Rent'}
                value={numeral(analyticsData?.opertaing_income).format()}
                loading={loading}
              />
            </div>
            <div className="col-xl-3 col-sm-3">
              <Card
                title={'Annualised Operating Income'}
                value={numeral(analyticsData?.opertaing_income * 0.6).format()}
                loading={loading}
              />
            </div>
            <div className="col-xl-3 col-sm-3">
              <Card
                title={'Gross Yield'}
                value={numeral(analyticsData?.opertaing_income / (analyticsData?.portfolio_value || 1)).format('0.00%')}
                loading={loading}
              />
            </div>
            <div className="col-xl-3 col-sm-3">
              <Card
                title={'Cap Rate'}
                value={numeral((analyticsData?.opertaing_income * 0.6) / (analyticsData?.portfolio_value || 1)).format(
                  '0.00%',
                )}
                loading={loading}
              />
            </div>
            <div className="col-xl-3 col-sm-3">
              <Card
                title={'Asset Return (Last 5 Years)'}
                value={numeral(analyticsData?.asset_return).format('0.00%')}
                loading={loading}
              />
            </div>
            <div className="col-xl-3 col-sm-3">
              <Card
                title={'Average Occupancy'}
                value={numeral(analyticsData?.average_occupancy / 100).format('0.00%')}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header pb-0 border-0 mb-3">
              <h4 className="fs-20 mb-0 mx-2 my-0 py-0 w-1/2 truncate"> {analyticsData?.name + ' Portfolio'} </h4>
              <span className="text-lg mx-4 font-medium" style={{ color: '#3571E5' }}>
                vs
              </span>

              <Select
                className="w-1/2"
                value={selectedIndex}
                onChange={handleChange}
                options={benchmarkDropdown}
                isSearchable={true}
              />
            </div>
            <div className="card-body py-0 px-sm-3 px-0">
              <div id="BarCharts2" className="bar-chart">
                {portfolioGraphSeriesData?.length ? (
                  <ItemVsIndexChart
                    itemGraphSeriesData={portfolioGraphSeriesData}
                    benchmarkGraphSeriesData={seriesObjectToArray(selectedIndex?.forecast || {}, targetTime)}
                    address={analyticsData?.name}
                    index={selectedIndex?.label}
                    secondaryAxisButtonEnabled={true}
                    extendedData={analyticsData.has_appraisal}
                  />
                ) : (
                  <div style={{ height: '350px' }} className="flex flex-col	justify-center items-center">
                    <LoaderSvg className="animate-spin h-16 w-16 text-white mx-auto my-8" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <RentCharts
        address={analyticsData?.name + ' Portfolio'}
        selectedIndex={selectedIndex}
        rentsByDistrict={rentsByDistrict}
        targetTime={targetTime}
        propertyAnalytics={analyticsData?.property_analytics}
        portfolioForecast={analyticsData?.portfolio_forecast}
      ></RentCharts>
      <KeyPerformanceMetricsTable
        itemPM={portfolioPM}
        benchmarkPM={benchmarkPM}
        type={'Portfolio'}
      ></KeyPerformanceMetricsTable>
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header border-0">
              <h4 className="mb-0 fs-20 text-black"> Properties By Type </h4>
            </div>
            <div className="card-body">
              <PieChartAnalytics pieChartData={pieChartData} />
            </div>
          </div>
        </div>
      </div>
      <PortfolioByPropertyTable
        sampleStatsDataGroupedAndSorted={sampleStatsDataGroupedAndSorted}
        portfolioValue={analyticsData?.portfolio_value}
      />

      <PropertiesMap
        properties={analyticsData?.property_analytics?.filter((item) => item.latitude && item.longitude)}
      />
    </>
  );
};
export default Analytics;
