import { Text, View, StyleSheet } from '@react-pdf/renderer';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#ccc',
    borderWidth: 1,
    marginBottom: 10,
    fontSize: 8,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableHeader: {
    fontSize: 11,
  },
  tableCell: {
    flex: 1,
    textAlign: 'center',
    borderStyle: 'solid',
    borderColor: '#ccc',
    borderWidth: 1,
    padding: 4,
  },
});

const propertyTypeMapping = {
  F: 'Flat',
  T: 'House',
  S: 'House',
  D: 'House',
};

const DepthOfMarketPDF = ({ property_type, number_of_bedrooms, district_code, area_code, district, area }) => {
  const mappedType = propertyTypeMapping[property_type] || property_type;

  let adjustedBedrooms = Number(number_of_bedrooms);

  if (mappedType === 'Flat' && number_of_bedrooms > 3) {
    adjustedBedrooms = 3;
  } else if (mappedType === 'House' && number_of_bedrooms > 4) {
    adjustedBedrooms = 4;
  }

  const districtItem = district.find(
    (item) => item.type === mappedType && item.number_of_bedrooms === adjustedBedrooms,
  );
  const districtData = districtItem?.depth_of_market
    ? JSON.parse(districtItem.depth_of_market.replace(/'/g, '"'))
    : null;

  const areaItem = area.find((item) => item.type === mappedType && item.number_of_bedrooms === adjustedBedrooms);
  const areaData = areaItem?.depth_of_market ? JSON.parse(areaItem.depth_of_market.replace(/'/g, '"')) : null;

  const ranges = districtData && typeof districtData === 'object' ? Object.keys(districtData) : [];

  const formattedRanges = ranges.map((range, index) => {
    if (index === 0) {
      return 'Under £500';
    } else if (index === ranges.length - 1) {
      return `£3750+`;
    } else {
      const [start, end] = range.split('-');
      return `£${start}-£${end}`;
    }
  });

  return (
    <View>
      <View style={styles.table}>
        <View style={[styles.tableRow, styles.tableHeader]}>
          <Text style={styles.tableCell}>Range</Text>
          <Text style={styles.tableCell}>District (%)</Text>
          <Text style={styles.tableCell}>Area (%)</Text>
        </View>
        {ranges.map((range, index) => (
          <View style={styles.tableRow} key={index}>
            <Text style={styles.tableCell}>{formattedRanges[index]}</Text>
            <Text style={styles.tableCell}>{(districtData[range] * 100).toFixed(0)}%</Text>
            <Text style={styles.tableCell}>{(areaData[range] * 100).toFixed(0)}%</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

export default DepthOfMarketPDF;
